import styled from '@emotion/styled';
import TextField from 'components/shared/textField/TextField';
import { StyleTextFieldProps } from 'components/shared/textField/TextField.consts';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import typography from 'styles/typography';

export const ExchangeLimitContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
`;

export const StyledLabelTooltipContainer = styled.div`
  display: flex;
`;

export const FormRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: ${typography.unit * 5}px;
  margin-right: 20px;
`;

export const StyledLabel = styled.label<StyleTextFieldProps>`
  font-size: 14px;
  color: ${({ theme, disabled }) => (!disabled ? theme.colors.text.primary : theme.colors.text.disabled)};
  margin-right: ${typography.unit}px;
`;

export const StyledInfoTooltip = styled(InfoTooltip)`
  display: inline-flex;
  position: relative;
  left: 4px;
`;

export const StyledNumberField = styled(TextField)`
  margin-bottom: ${typography.unit * 4}px;
  input {
    width: 201.61px;
  }
  [data-automation-id~='error-message'] {
    white-space: normal;
  }
`;
