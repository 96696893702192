import styled from '@emotion/styled';
import Modal from 'components/shared/modal/Modal';
import typography from 'styles/typography';
import { StyledSectionTitle } from 'pages/shared/shared.style';

export const StyledModal = styled(Modal)`
  height: 85vh;
  width: 700px;

  & > header {
    padding-bottom: ${typography.unit * 5}px;

    h3 {
      margin-bottom: ${typography.unit}px;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  margin-right: -${typography.unit * 5}px;

  ::-webkit-scrollbar {
    width: 21px;
  }

  ::-webkit-scrollbar-thumb {
    border: 7px transparent solid;
    background-clip: padding-box;
    border-radius: 10px;
  }
`;

export const StyledSectionTitleWithLoader = styled(StyledSectionTitle)`
  display: inline-flex;
  align-items: center;

  span {
    margin-left: 10px;
  }

  div {
    margin-left: 10px;
  }
`;
