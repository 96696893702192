import styled from '@emotion/styled/macro';
import { Icon, IconProps } from 'components/shared/icon';
import { ButtonContained } from 'components/shared/button';
import { Loader } from 'components/shared/loader';
import { defaultTheme } from 'styles/themes/defaultTheme';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import TotalAmount from 'components/shared/totalAmount/TotalAmount';
import { ThemeProps } from '../settings/termsConditions/TermsConditions.consts';
import { SelectboxPaginate } from 'components/shared/selectboxPaginate/SelectboxPaginate';

export const StyledHeader = styled.header<ThemeProps>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.global.border};
  padding: ${typography.unit * 6}px ${typography.unit * 6}px ${typography.unit * 5}px ${typography.unit * 6}px;
`;

export const Actions = styled.div<ThemeProps>`
  display: flex;
  justify-content: space-between;
`;

export const FilterIcon = styled(Icon)<IconProps>`
  width: 13px;
  fill: ${({ theme }) => theme.colors.text.secondary};
  margin-right: ${typography.unit * 4}px;
`;

export const StyledLoader = styled(Loader)`
  margin: auto;
  display: flex;
`;

export const StyledSearch = styled(Icon)<IconProps>`
  fill: ${defaultTheme.colors.global.border};
  width: 16px;
`;

export const ColoredButtonBase = styled(ButtonContained)`
  color: ${({ theme }) => theme.colors.text.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const StyledApproveButton = styled(ColoredButtonBase)`
  background-color: ${({ theme }) => theme.colors.button.approve.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.button.approve.hover};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.disabled};
    background-color: ${({ theme }) => theme.colors.button.approve.disabled};
  }
`;

export const StyledRejectButton = styled(ColoredButtonBase)`
  background-color: ${({ theme }) => theme.colors.button.reject.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.button.reject.hover};
  }
`;

export const StyledRevokeButton = styled(ColoredButtonBase)`
  background-color: ${({ theme }) => theme.colors.button.revoke.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.button.revoke.hover};
  }
`;

export const StyledFormSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${typography.unit * 12}px;
`;

export const StyledSectionTitle = styled.div<StyledWithThemeProps>`
  font-size: 16px;
  padding-bottom: ${typography.unit * 2}px;
  margin-bottom: ${typography.unit * 4}px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.global.border};`};
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${typography.unit * 4}px ${typography.unit * 6}px;
  height: 100%;

  .MuiDataGrid-root {
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;

    .MuiDataGrid-main,
    .MuiDataGrid-columnHeader,
    .MuiDataGrid-columnHeaderTitleContainer,
    .MuiDataGrid-cell {
      padding-left: 0 !important;
      justify-content: start !important;
    }
  }
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: ${typography.unit * 5}px;
  flex: 1;
`;

export const StyledFilterBarSelect = styled(Selectbox)`
  margin-right: ${typography.unit * 7}px;
` as unknown as typeof Selectbox;

const StyledTotalAmount = styled(TotalAmount)`
  align-self: flex-end;
`;

export const StyledTotalAmountWithTable = styled(StyledTotalAmount)`
  margin-bottom: ${typography.unit * 4}px;
`;

export const StyledTotalAmountWithGrid = styled(StyledTotalAmount)`
  padding: ${typography.unit * 4}px ${typography.unit * 7}px;
`;

export const StyledFilterBarSelectPaginate = styled(SelectboxPaginate)`
  margin-right: ${typography.unit * 7}px;
` as unknown as typeof SelectboxPaginate;
