import React, { useEffect, useState } from 'react';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { SetItemsSelectionFormState } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { FormMode, GenericEntity, SetOfEntity } from 'utils/types';
import { useFormContext } from 'react-hook-form';
import {
  getAllItemsRecursively,
  getSetsFringesItems,
  getSetsNestedSetsRecursively,
  getTotalSelectedItems,
} from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import cloneDeep from 'lodash/cloneDeep';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';
import { getLocationSets } from 'utils/api/locationSets';
import { buildObjectBy } from 'utils/mapping';
import { getLocationsByCodes } from 'utils/api/locations';
import { Location, LocationSet, LocationSetType, RestaurantGroup } from 'utils/types/locations';
import { NetworkStatus, useQuery } from '@apollo/client';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import { Game, GamePlatform } from 'utils/types/games';
import { ButtonText } from 'components/shared/button';
import Error from 'components/shared/error/Error';
import locationSetsGqls from 'pages/settings/locationSets/LocationSets.gqls';
import { isObject } from 'utils/object';
import { LocationSectionProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/locationSection/LocationSection.consts';
import {
  SectionContainer,
  SelectLocationsWrapper,
  StyledIcon,
  StyledCheckbox,
  LoaderSpace,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/locationSection/LocationSection.style';
import { FetchPolicies } from 'utils/types/common';
import { Loader } from 'components/shared/loader';
import { LoaderSize } from 'components/shared/loader/Loader.consts';

const restaurantPath = 'restaurantEligibility.restaurants';
const restaurantGroupsPath = 'restaurantEligibility.restaurantGroups';
const excludeRestaurantGroupsPath = 'restaurantEligibility.excludeRestaurantGroups';
const excludeRestaurantsPath = 'restaurantEligibility.excludeRestaurants';
const isNationWidePath = 'restaurantEligibility.isNationwide';

const LocationSection = ({ mode, campaign, className, isDisabled }: LocationSectionProps) => {
  const [modifiedCampaign, setModifiedCampaign] = useState(campaign);
  const { getValues, trigger, register, setValue, watch } = useFormContext();
  const [
    selectedGameName,
    gamingPlatform,
    restaurants,
    restaurantGroups,
    excludeRestaurantGroups,
    excludeRestaurants,
    isLocalCampaign,
    zoneSelection,
    isNational,
    isNationwide,
  ] = watch([
    'voucherConfig.game',
    'voucherConfig.platform',
    restaurantPath,
    restaurantGroupsPath,
    excludeRestaurantGroupsPath,
    excludeRestaurantsPath,
    'isLocalCampaign',
    'localSchedule.zone',
    'isNational',
    isNationWidePath,

  ]);
  const [totalLocations, setTotalLocations] = useState(null);
  const [locationSets, setLocationSets] = useState<LocationSet[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [excludeLocationSets, setExcludeLocationSets] = useState<LocationSet[]>([]);
  const [excludeLocations, setExcludeLocations] = useState<Location[]>([]);
  const supportSetFringes = gamingPlatform !== GamePlatform.iWin;
  const [shouldShowZoneError, setShouldShowZoneError] = useState(false);
  const isTriggerEvent = watch('isTriggerEvent');
  const [loading, setLoading] = useState(true);

  const { data: selectedGame, loading: gameLoading, networkStatus: gameNetworkStatus } = useQuery(
    campaignsGqls.queries.getGames,
    {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      skip: !selectedGameName || isNationwide,
      variables: {
        data: {
          filters: {
            AND: [{ name: { is: selectedGameName } }],
          },
        },
      },
    },
  );

  const { data: selectedLocationsByZoneData, loading: locationSetsLoading, networkStatus: locationSetsNetworkStatus } = useQuery(
    locationSetsGqls.queries.getAllLocationSets,
    {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      skip: (!isLocalCampaign || (!zoneSelection && !isNational)) && !isNationwide,
      variables: {
        data: {
          filters: {
            [LocationSetsFilters.CustomSets]: false,
            ...(isNationwide
              ? {}
              : {
                [LocationSetsFilters.LocationSetsIds]: isObject(zoneSelection)
                  ? [zoneSelection.id]
                  : isNational
                    ? null
                    : [zoneSelection],
              }),
          },
        },
      },
    },
  );


  const updateLocations = async (restaurantsArr: number[]) =>
    setLocations(restaurantsArr?.length ? await getLocationsByCodes(restaurantsArr as unknown as number[]) : []);

  const updateLocationSets = async (
    restaurantGroupsArr: LocationSet[],
    excludeRestaurantsArr: number[],
    excludeRestaurantGroupsArr: LocationSet[],
  ) => {
    const filters = {
      [LocationSetsFilters.LocationSetsExternalIds]: restaurantGroupsArr?.map((rGroup) => ({
        id: rGroup?.id,
        type: rGroup?.type,
      })),
    };
    const limit = restaurantGroupsArr?.length ?? 0;
    const result = restaurantGroupsArr?.length ? (await getLocationSets(filters, limit, 0)).items : [];
    setLocationSets(result);
    const locationsByCode = buildObjectBy(
      'code',
      getAllItemsRecursively(result, SetOfEntity.Locations, true) as Location[],
    );
    const setsByExternalId = buildObjectBy('externalId', getSetsNestedSetsRecursively(result));
    setExcludeLocations(
      (excludeRestaurantsArr || [])
        ?.map((code: number) => locationsByCode[code])
        .filter((excRes) => excRes) as Location[],
    );
    setExcludeLocationSets(
      (excludeRestaurantGroupsArr || [])
        ?.map((rGroup) => setsByExternalId[rGroup.id])
        .filter((excResGroup) => excResGroup) as LocationSet[],
    );
    const isGameReady = gameNetworkStatus === NetworkStatus.ready;
    const isLocationSetsReady = locationSetsNetworkStatus === NetworkStatus.ready;
    if (isGameReady && isLocationSetsReady || (selectedLocationsByZoneData)) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  };

  const validateLocations = () => {
    const nonExcludedLocations = getTotalSelectedItems(
      locationSets,
      locations,
      excludeRestaurantGroups,
      excludeRestaurants,
      SetOfEntity.Locations,
      supportSetFringes,
    );
    return nonExcludedLocations > 0 ? null : `At least one location or location set should be selected`;
  };

  register(excludeRestaurantGroupsPath, {
    validate: validateLocations,
  });
  register(excludeRestaurantsPath, {
    validate: validateLocations,
  });
  register(restaurantPath, {
    validate: validateLocations,
  });
  register(restaurantGroupsPath, {
    validate: validateLocations,
  });
  register(isNationWidePath, {
    validate: validateLocations,
  });

  const onOpenLocationsModal = async () => {
    if (isLocalCampaign && !zoneSelection && !isNational) {
      setShouldShowZoneError(true);
    } else {
      setShouldShowZoneError(false);
      const campaign1 = cloneDeep(getValues());
      store.dispatch(
        openModal({
          modal: Modals.LocationSetModal,
          props: {
            restaurantEligibility:
              selectedGame?.getGames.items?.[0].restaurantEligibility ||
                selectedLocationsByZoneData?.getLocationSets?.items
                ? {
                  restaurantGroups: selectedLocationsByZoneData?.getLocationSets?.items.map(
                    (locationSet: LocationSet) => ({
                      id: locationSet.externalId,
                      type: LocationSetType.Hierarchy,
                    }),
                  ),
                  restaurants: getSetsFringesItems(selectedLocationsByZoneData?.getLocationSets?.items).map(
                    (fringe: any) => fringe.code,
                  ),
                }
                : undefined,
            locationSet: {
              locations,
              sets: locationSets,
              excludedItems: excludeLocations,
              excludedSets: excludeLocationSets,
            },
            supportSetFringes,
            onEditFormMode: FormMode.Select,
            mode:
              mode === FormMode.View || (isLocalCampaign && !isTriggerEvent) || isNationwide || selectedGameName
                ? FormMode.SelectionView
                : FormMode.Select,
            isZoneSelection: isLocalCampaign && isTriggerEvent && mode !== FormMode.View,
            onSave: (result: SetItemsSelectionFormState) => {
              const selectedItems = Object.values(result.selectedItemsById) as Location[];
              const selectedSets = Object.values(result.selectedItemSetsById) as LocationSet[];
              const excludeRestaurantsCodes = (Object.values(result.excludedItemsById) as Location[])?.map(
                (location) => location.code,
              );
              const excludeRestaurantGroupsExternalIds = (
                Object.values(result.excludedItemSetsById) as LocationSet[]
              )?.map((locationSet) => ({
                id: locationSet.externalId,
                type: locationSet.custom ? LocationSetType.Custom : LocationSetType.Hierarchy,
              }));
              store.dispatch(
                openModal({
                  modal: Modals.CampaignModal,
                  props: {
                    mode,
                    campaign: {
                      ...(campaign1 || {}),
                      restaurantEligibility: {
                        restaurants: selectedItems.map((location: Location) => location.code),
                        restaurantGroups: selectedSets.map((set: LocationSet) => ({
                          id: set.externalId,
                          type: set.custom ? LocationSetType.Custom : LocationSetType.Hierarchy,
                        })),
                        excludeRestaurants: excludeRestaurantsCodes,
                        excludeRestaurantGroups: excludeRestaurantGroupsExternalIds,
                      },
                      totalLocations: undefined,
                    },
                  },
                }),
              );
            },
            onCancel: () =>
              store.dispatch(
                openModal({
                  modal: Modals.CampaignModal,
                  props: { mode, campaign: campaign1 },
                }),
              ),
          },
        }),
      );
    }
  };

  const resetOnIsNationalCampaignChange = () => {
    setValue(restaurantPath, null);
    setValue(restaurantGroupsPath, null);
    setValue(excludeRestaurantsPath, null);
    setValue(excludeRestaurantGroupsPath, null);
    setValue(isNationWidePath, !isNationwide);
  };

  const resetOnIsNationalCampaignChangeUponEdit = () => {
    setValue(isNationWidePath, !isNationwide);
  };

  useEffect(() => {
    if (!isLocalCampaign) {
      setShouldShowZoneError(false);
    }
  }, [isLocalCampaign]);
  useEffect(() => {
    const fetchDataAndSetValue = async () => {
      try {
        if (
          (selectedLocationsByZoneData?.getLocationSets?.items && !isTriggerEvent) ||
          (isTriggerEvent && (isNational || isNationwide || zoneSelection))
        ) {
          const items = selectedLocationsByZoneData?.getLocationSets?.items;
          const updatedRestaurantGroups: RestaurantGroup[] = [];
          const updatedRestaurants: number[] = [];
          items?.forEach((locationSet: LocationSet) => {
            locationSet.dummy
              ? updatedRestaurants.push(...locationSet.locations.map((l) => l.code))
              : updatedRestaurantGroups.push({
                id: locationSet.externalId,
                type: LocationSetType.Hierarchy,
              });
          });
          setValue(restaurantGroupsPath, updatedRestaurantGroups);
          setValue(restaurantPath, updatedRestaurants.length ? updatedRestaurants : null);
        }
      } catch (error) { }
    };
    fetchDataAndSetValue();
  }, [selectedLocationsByZoneData, isTriggerEvent, isNational, isNationwide, zoneSelection, setValue]);

  useEffect(() => {
    updateLocations(restaurants);
  }, [restaurants]);

  useEffect(() => {
    if (zoneSelection || isNational) {
      setShouldShowZoneError(false);
    }
  }, [zoneSelection, isNational]);

  useEffect(() => {
    updateLocationSets(restaurantGroups, excludeRestaurants, excludeRestaurantGroups);
  }, [restaurantGroups, excludeRestaurantGroups, excludeRestaurants, gameNetworkStatus]);

  useEffect(() => {
    if (!isNationwide || (isNationwide && locationSets.length > 0)) {
      const nonExcludedLocations = getTotalSelectedItems(
        locationSets,
        locations,
        excludeLocationSets,
        excludeLocations,
        SetOfEntity.Locations,
        supportSetFringes,
      );

      trigger(restaurantGroupsPath);
      trigger(excludeRestaurantGroupsPath);
      trigger(excludeRestaurantsPath);
      trigger(restaurantPath);

      setTotalLocations(nonExcludedLocations);
    }
  }, [locationSets, locations, excludeLocationSets, excludeLocations, supportSetFringes]);

  useEffect(() => {
    if (mode === FormMode.Edit) {
      const newCampaign = { ...campaign, totalLocations: 0 };
      setModifiedCampaign(newCampaign);
    }
  }, [isNational, isNationwide, zoneSelection]);
  return (
    <SectionContainer className={className} data-automation-id="location-section">
      <>
        <SelectLocationsWrapper>
          {loading ? (
            <><Loader size={LoaderSize.ExtraSmall} /><LoaderSpace></LoaderSpace></>
          ) : (
            <ButtonText onClick={onOpenLocationsModal}>
              {restaurants || restaurantGroups ? (
                <StyledIcon
                  name={
                    mode === FormMode.View ||
                      (isLocalCampaign && !isTriggerEvent) ||
                      (isNationwide && (mode !== FormMode.Edit || mode === FormMode.Edit)) ||
                      selectedGameName
                      ? 'view'
                      : 'edit'
                  }
                />
              ) : mode === FormMode.View ? (
                ''
              ) : (
                'Select Locations*'
              )}
            </ButtonText>
          )}
          {shouldShowZoneError && <Error errors="Zone selection is required" />}
        </SelectLocationsWrapper>
        {(mode === FormMode.View || mode === FormMode.Edit) && modifiedCampaign?.totalLocations > 0
          ? `${modifiedCampaign.totalLocations} selected locations`
          : totalLocations > 0
            ? `${totalLocations} selected locations`
            : ''}
        {!(isLocalCampaign || gamingPlatform === GamePlatform.iWin) && (
          <StyledCheckbox
            checked={isNationwide}
            onClick={
              mode !== FormMode.Edit
                ? () => resetOnIsNationalCampaignChange()
                : () => resetOnIsNationalCampaignChangeUponEdit()
            }
            label="Make National"
            disabled={mode === FormMode.View}
          />
        )}
      </>
    </SectionContainer>
  );
};

export default LocationSection;

