import { UsageEntityType } from 'app/genericSlices/usage';
import { Impact } from '../impactItem/ImpactItem.consts';
import {
  ImageArchiveNotification,
  ImageCantArchiveNotification,
  ImageSaveNotification,
  ImageUsage,
  LocationSetDeleteNotification,
  LocationSetDeleteSubtitle,
  LocationSetSaveNotification,
  LocationSetUsage,
  LocationUsage,
  OfferArchiveNotification,
  OfferEditSegmentIdNotification,
  OfferRejectNotification,
  OfferSaveNotificationHeader,
  OfferUsage,
  ProductSetDeleteNotification,
  ProductSetDeleteSubtitle,
  ProductSetSaveNotification,
  ProductSetUsage,
  ProductUsage,
  TagUsageDeleteNotification,
  TagUsageNotification,
  TermArchiveNotification,
  TermCantArchiveNotification,
  TermSaveNotification,
  TagSaveNotificationType,
} from './ImpactModalHeaders';
import { OfferSaveNotificationInfo } from './ImpactModalInfos';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';

export interface ImpactModalProps {
  title: string;
  headerComponentType?: HeaderComponentType;
  infoComponentType?: InfoComponentType;
  entityType: 'TermCondition' | 'Offer' | 'Image' | 'Tag';
  entityId: string | number;
  campaignImpacts: Impact[];
  offerImpacts?: Impact[];
  imageImpacts?: Impact[];
  onCancel?: () => void;
  onSubmit?: (item: any) => void;
  submitButtonString?: string;
  className?: string;
}

export interface UsageModalProps {
  title: string;
  headerComponentType?: HeaderComponentType;
  entityType: UsageEntityType;
  entityId: string | number;
  onCancel?: () => void;
  onSubmit?: (item: any) => void;
  haveCampaign?: boolean;
  haveOffer?: boolean;
  className?: string;
  offerVersionId?: number;
  submitButtonString?: string;
  offerSource?: OfferSource;
}

export interface ChangesImpactProps {
  onCancel?: () => void;
  campaignIds: number[];
  entityId: string | number;
  showOffers: boolean;
  offerIds: number[];
  title: string;
}

export enum HeaderComponentType {
  OfferSaveNotificationHeaderType,
  OfferArchiveNotificationType,
  OfferUsageType,
  OfferRejectNotificationType,
  OfferEditSegmentIdNotification,
  ImageCantArchiveNotificationType,
  ImageArchiveNotificationType,
  ImageSaveNotificationType,
  ImageUsageType,
  TermCantArchiveNotificationType,
  TermArchiveNotificationType,
  TermSaveNotificationType,
  ProductSetUsageType,
  ProductSetSaveNotificationType,
  ProductSetDeleteSubtitleType,
  ProductSetDeleteNotificationType,
  LocationSetUsageType,
  LocationSetSaveNotificationType,
  LocationSetDeleteSubtitleType,
  LocationSetDeleteNotificationType,
  TagDeleteUsageType,
  TagUsageType,
  ProductUsageType,
  LocationUsageType,
  TagSaveNotificationType,
}

export enum InfoComponentType {
  OfferSaveNotificationInfoType,
}

export const ModalHeaderComponentMap: Record<HeaderComponentType, (props: any) => JSX.Element> = {
  [HeaderComponentType.OfferSaveNotificationHeaderType]: OfferSaveNotificationHeader,
  [HeaderComponentType.OfferArchiveNotificationType]: OfferArchiveNotification,
  [HeaderComponentType.OfferEditSegmentIdNotification]: OfferEditSegmentIdNotification,
  [HeaderComponentType.OfferUsageType]: OfferUsage,
  [HeaderComponentType.OfferRejectNotificationType]: OfferRejectNotification,
  [HeaderComponentType.ImageCantArchiveNotificationType]: ImageCantArchiveNotification,
  [HeaderComponentType.ImageArchiveNotificationType]: ImageArchiveNotification,
  [HeaderComponentType.ImageSaveNotificationType]: ImageSaveNotification,
  [HeaderComponentType.ImageUsageType]: ImageUsage,
  [HeaderComponentType.TermCantArchiveNotificationType]: TermCantArchiveNotification,
  [HeaderComponentType.TermArchiveNotificationType]: TermArchiveNotification,
  [HeaderComponentType.TermSaveNotificationType]: TermSaveNotification,
  [HeaderComponentType.ProductSetUsageType]: ProductSetUsage,
  [HeaderComponentType.ProductSetSaveNotificationType]: ProductSetSaveNotification,
  [HeaderComponentType.ProductSetDeleteSubtitleType]: ProductSetDeleteSubtitle,
  [HeaderComponentType.ProductSetDeleteNotificationType]: ProductSetDeleteNotification,
  [HeaderComponentType.LocationSetUsageType]: LocationSetUsage,
  [HeaderComponentType.LocationSetSaveNotificationType]: LocationSetSaveNotification,
  [HeaderComponentType.LocationSetDeleteSubtitleType]: LocationSetDeleteSubtitle,
  [HeaderComponentType.LocationSetDeleteNotificationType]: LocationSetDeleteNotification,
  [HeaderComponentType.TagDeleteUsageType]: TagUsageDeleteNotification,
  [HeaderComponentType.TagUsageType]: TagUsageNotification,
  [HeaderComponentType.ProductUsageType]: ProductUsage,
  [HeaderComponentType.LocationUsageType]: LocationUsage,
  [HeaderComponentType.TagSaveNotificationType]: TagSaveNotificationType,
};

export const ModalInfoComponentMap: Record<InfoComponentType, (props: any) => JSX.Element> = {
  [InfoComponentType.OfferSaveNotificationInfoType]: OfferSaveNotificationInfo,
};
