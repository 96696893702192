import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import ReactTooltip from 'react-tooltip';
import isEqual from 'lodash/isEqual';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { OfferTemplates } from 'utils/types/offers';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { hasValue } from 'utils/text';
import { hideTooltip } from 'utils/tooltip';
import { FormMode } from 'utils/types';
import { store } from 'app/store';
import { closeModal } from 'app/slices/modals';
import { termsConditionsPage } from 'app/genericSlices/termsConditions';
import pick from 'lodash/pick';
import { onTermEdit } from 'pages/settings/termsConditions/termsConditions.utils';
import { TermConditionProps, TermTranslation } from '../../TermsConditions.consts';
import {
  StyledButtonContained,
  StyledContent,
  TermConditionName,
  StyledSelectbox,
  TermConditionFormContainer,
  StyledButtonsContainer,
  StyledButtonText,
  StyledTermConditionTranslations,
  StyledModal,
} from './TermConditionForm.style';
import { TermConditionFormProps } from './TermConditionForm.consts';
import { termsConditionsGqls } from '../../TermsConditions.gqls';
import { ValidationMessages } from 'utils/types/common';
import { marketConfig } from 'app/slices/config';
import { useSelector } from 'react-redux';

const TermConditionForm = ({ termCondition, mode, editedTerm, ...rest }: TermConditionFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
    setValue,
    trigger,
  } = useForm({ mode: 'onChange', defaultValues: editedTerm || (termCondition as any) });
  const { id, translationsMap } = termCondition;
  const [addTermCondition] = useMutation(termsConditionsGqls.mutations.add);

  const onSave = async (data: TermConditionProps) => {
    try {
      data.translations = data?.translations.filter((translation: TermTranslation) => translation.content);
      if (data?.id) {
        const termToUpdate = {
          ...pick(data, ['id', 'name', 'template', 'isArchive', 'translations']),
        } as any as TermConditionProps;
        const showImpactModal =
          !isEqual(
            {
              name: data.name,
              translations: data.translations,
            },
            {
              name: termCondition.name,
              translations: Object.values(termCondition.translationsMap),
            },
          ) ||
          !isEqual(
            {
              name: editedTerm?.name,
              translations: editedTerm?.translations,
            },
            {
              name: termCondition.name,
              translations: Object.values(termCondition.translationsMap),
            },
          );
        onTermEdit(termCondition, termToUpdate, showImpactModal);
      } else {
        await addTermCondition({ variables: { data } });
        showToast(MessageType.Success, 'Terms & Conditions added successfully');
        store.dispatch(termsConditionsPage.loadTerms());
        reset(termCondition);
      }
      store.dispatch(closeModal());
    } catch (e) {
      showToast(
        MessageType.Error,
        `Failed to ${data?.id ? 'update' : 'add'} terms and conditions${
          e.message.includes('duplication item') ? ` - ${data.name} already exists` : ''
        }`,
      );
    }
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setValue('id', id);
  }, [setValue, id]);

  useEffect(() => {
    if (id) {
      trigger();
    }
  }, [id]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isDirty]);

  const { config } = useSelector(marketConfig);
  const { enableDigitalDownload } = config;
  const filteredOfferTemplates = Object.values(OfferTemplates).filter(
    (template) => enableDigitalDownload || template.id !== '14'
  );
  return (
    <StyledModal
      title={`${mode === FormMode.New ? 'Add Terms & Conditions' : `Edit Terms & Conditions ${termCondition.name}`}`}
    >
      <TermConditionFormContainer onKeyDown={(e) => checkKeyDown(e)} {...rest} data-automation-id="term-form">
        <StyledContent>
          <TermConditionName
            register={register}
            name="name"
            label="Name"
            errors={errors}
            labelIsHorizontal
            placeholder="Enter"
            validation={{
              required: ValidationMessages.RequiredField,
              maxLength: { value: 200, message: 'Up to 200 characters' },
              validate: (value: string) => (!hasValue(value) ? ValidationMessages.RequiredField : true),
            }}
          />
          <StyledSelectbox
            placeholder="Select"
            label="Offer Template"
            labelIsHorizontal
            multiple
            items={Object.values(filteredOfferTemplates) as any[]}
            reset
            name="template"
            control={control}
            maxItems={3}
            errors={errors}
            validation={{
              required: 'Please select an offer template from the list',
            }}
          />
          <StyledTermConditionTranslations
            register={register}
            setValue={setValue}
            errors={errors}
            translationsMap={translationsMap}
          />
        </StyledContent>
        <StyledButtonsContainer>
          <StyledButtonText
            data-for="cancel-tooltip"
            {...(isDirty ? { 'data-tip': true, onClick: () => null } : { onClick: () => store.dispatch(closeModal()) })}
          >
            Cancel
          </StyledButtonText>
          <StyledButtonContained onClick={handleSubmit(onSave)} disabled={!isValid || (!isDirty && !editedTerm)}>
            Save
          </StyledButtonContained>
          <Tooltip
            id="cancel-tooltip"
            content="Are you sure you want to cancel?"
            onDisapproveClick={() => {
              hideTooltip('#cancel-tooltip');
            }}
            onApproveClick={() => store.dispatch(closeModal())}
          />
        </StyledButtonsContainer>
      </TermConditionFormContainer>
    </StyledModal>
  );
};

export default TermConditionForm;
